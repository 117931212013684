import React, { ComponentType, ComponentProps, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
    to: string;
    align: string;
    children: ReactNode;
    onClick?: React.MouseEventHandler;
    className?: string;
    isCollapsed?: boolean;
}

function CoolNavLink({ to, align, children, onClick, className, isCollapsed }: Props) {
    const rest_class = `select-none p-2 ml-2 border border-transparent hover:border-gray-100 hover:rounded transition-[width] duration-500 text-${align} ${className ? className : ''} ${isCollapsed ? 'w-10' : 'w-full'}`; 
    const isActive = ({ isActive }: { isActive: boolean }) => (isActive ? 'bg-gray-100 rounded ' + rest_class : ' ' + rest_class);

    return (
        <NavLink to={to} className={isActive} onClick={onClick}>
            {isCollapsed ? ((children as string).split(' ').map((word: string, index: number) => (<span key={index}>{word.charAt(0)}</span>))) : children}
        </NavLink>
    )
}

export default CoolNavLink;

