import CustomInput from "./components/CustomInput";
import { useEffect, useState } from 'react';
import { db_Login } from './models';
import { toast, ToastContainer } from 'react-toastify';
import store from "./redux/store";
import { loadSettings } from "./redux/globals/globalsSlice";

const sha256 = require("crypto-js/sha256")



function Login() {
    
    useEffect(() => {
        localStorage.removeItem('token');
        document.body.style.backgroundColor = '#bdb7ae';
    }, []);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async (e: any) => {
        const pw_hash = sha256(password).toString();
        let res = await fetch(process.env.REACT_APP_API_URL + '/Login',  {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            "body": JSON.stringify({
                "username": username,
                "password": pw_hash
            })
        }).catch(err => { 
            toast.error(err.toString());
        });
        if(res) {
            if (!res.ok) 
            {
                alert('Falscher Benutzername oder Passwort');
                return;
            }
            let res_json = await res.json(); 
            // store token
            localStorage.setItem('token', res_json.jwtoken);
            window.location.replace('/site-planning');
            store.dispatch(loadSettings);
        }
    };

    const onKeyUp = (e: any) => {
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        //if enter was pressed
        if (charCode === 13) 
        {
            onSubmit(e);
        }
    };

    return (
        <div className='flex flex-col items-center' style = {{ margin: '100px' }}>
            <img className='' src='/Meier-Schmocker.svg' alt='Meier Schmocker AG' />
            <div onKeyUp={onKeyUp} className='bg-gray-100 shadow-xl p-10 m-20 rounded-xl w-1/2 min-w-min'>
                <div className='flex item-center justify-between my-5'>
                    <h1 className='text-2xl font-bold'>Login</h1>
                </div>
                <div className='flex-row item-center justify-between my-5' >
                    <CustomInput id='username' placeholder='username' type='text' onChange={e => {setUsername(e.target.value)}} />
                    <CustomInput id='password' placeholder='password' type='password' onChange={e => {setPassword(e.target.value)}} />
                    <button className='bg-blue-100 text-blue-900 hover:bg-blue-200 rounded p-2 pr-2 inline-flex flex-row text-sm font-semibold' onClick={e => {onSubmit(e)}}>Login</button>
                </div>
            </div>
        </div>
    );
}

export default Login;