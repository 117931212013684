import moment from 'moment';
import { useRef } from 'react';

const eventToState = (setState: any) => ((e: any) => {
    setState(e.target.value);
});

const getStartDate = (startTime: string | undefined) => {
    if(startTime == null) {
        return '-';
    }
    const formattedStartDate = moment.utc(startTime).format('DD.MM.YYYY');
    return formattedStartDate;
}

const getEndDate = (endTime: string | undefined) => {
    if(endTime == null) {
        return '-';
    }
    const formattedEndDate = moment.utc(endTime).subtract(1, 'days').format('DD.MM.YYYY');
    return formattedEndDate;
}

const getStartDateDB = (startTime: string | moment.Moment | undefined) => {
    if(startTime == null || startTime == '') {
        return ''; //drop entry to remove
    }
    const dateObj = moment.isMoment(startTime) ? startTime : moment.utc(startTime);
    dateObj.set('hour', Math.round(dateObj.get('hour') / 12) * 12);
    const formattedStartDate = dateObj.format('YYYY-MM-DD HH:mm:ss');
    return formattedStartDate;
}

// create: add = 1, get: add = -1
const getEndDateDB = (endTime: string | moment.Moment | undefined, add : number) => {
    if(endTime == null || endTime == '') {
        return ''; //drop entry to remove
    }
    const dateObj = moment.isMoment(endTime) ? endTime : moment.utc(endTime);
    dateObj.set('hour', Math.round(dateObj.get('hour') / 12) * 12);
    const formattedEndDate = dateObj.add(add, 'days').format('YYYY-MM-DD HH:mm:ss');
    return formattedEndDate;
}

const getKWFromDate = (date: moment.Moment) => {
    return date.format('w');
}

const getDateFromKW = (kw: string) => {
    if(kw == null || kw == '') 
    {
        return getStartDateDB(moment());
    }
    let kw_num = +kw;
    let date = moment().startOf('year').weekday(0);
    if(date.week() !== 1 )
    {
        date.weekday(0).add(1, 'week');
    }
    //set week number
    date.week(kw_num);
    return getStartDateDB(date);
}

const getShortName = (name: string | undefined) => {
    if(name == null) 
    {
        return '';
    }
    let shortNames = name.split(' ');
    // get first two letters of all names separated by space
    shortNames = shortNames.map((name: string) => name.substring(0, 2));
    let shortName = shortNames.join('');
    return shortName;
}

export { eventToState, getStartDate, getStartDateDB, getEndDate, getEndDateDB, getKWFromDate, getDateFromKW, getShortName };
