import { useEffect, useState } from 'react';
import { db_Global } from '../models';
import CustomInput from '../components/CustomInput';
import { fetcher } from '../request';
import store from '../redux/store';
import { updateSettings } from '../redux/globals/globalsSlice';


function Settings() {
    let [settings, setSettings] = useState<db_Global[]>([]);

    useEffect(() => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Globals');
        req.then(res => res.json()).then(data => setSettings(data));
    }, []);

    const updateSettingValue = async (name: string, new_val: string) => {
        const newGlobal : db_Global = { id_Globals: -1, Name: name, Value: new_val, Active: true };
        store.dispatch(updateSettings(newGlobal));
        
        let data: db_Global[] = JSON.parse(JSON.stringify(settings));
        data.filter(r => r.Name === name)[0].Value = new_val;
        setSettings(data);
        // customInput.value = new_val;
    }

    const row = (setting: db_Global, isEven: boolean) => {
        
        let updateState = (new_val: string) => setting.Value = new_val;

        return (
            <div key={setting.id_Globals} className={(isEven ? 'bg-white ' : 'bg-gray-50 ') + 'px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
                <dd className="mt-1 text-sm text-gray-900">{setting.Name}</dd>
                <CustomInput id={setting.id_Globals.toString()} value={setting.Value} type='text' onChange={e => { updateSettingValue(setting.Name, e.target.value); }} />
            </div>
        );
    }

    return (
        <div>
            <div className='flex item-center justify-between my-5'>
                <h1 className='text-2xl font-bold'>Einstellungen</h1>
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-y border-gray-200">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dt className="text-sm font-medium text-gray-500">Value</dt>
                </div>
                {settings.map((setting, index) => (row(setting, index % 2 === 0)))}
            </dl>
        </div>
    );
}

export default Settings;