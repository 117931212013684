import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, TrashIcon, PencilIcon, CheckIcon } from '@heroicons/react/outline';
import React, { CSSProperties, useLayoutEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { v_PersonSite, db_Availability, viewModes, db_Site } from '../models';
import { ItemTypes } from './dndModels';
import Event from './Event';
import { fetcher } from '../request';
import store from '../redux/store';
import { deletePersonSite, updatePersonSite } from '../redux/personSites/personSitesSlice';
import AsyncSelect from 'react-select/async';
import { getEndDateDB, getShortName, getStartDateDB } from '../helpers';

type Props = {
    id: React.Key,
    personSite: v_PersonSite,
    className?: string
    gridRow: number,
    gridColumn: string | number,
    gridColumnSpan: number,
    actualColumn: number,
    actualColumnSpan: number,
    props: {
        [key: string]: string | number
    },
    moveResizeCell: (personSite: v_PersonSite, startColDiff: number, endColDiff: number, rowDiff: number, row: number) => Promise<void>,
    isActive: boolean,
    onClick: (e: React.MouseEvent, isActive: boolean) => void,
    timeFrame: number,
    gridContainer: React.RefObject<HTMLDivElement>,
    mode: viewModes,
    isNew?: boolean,
    hasGap?: boolean,
}

function getStyles(
    gridRow: string | number,
    gridColumn: string | number,
    gridColumnSpan: number,
    isDragging: boolean,
    hasGap?: boolean,
): CSSProperties {
    return {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: isDragging ? 'none' : 'auto',
        zIndex: 2,
        cursor: 'grab',
        opacity: isDragging ? 0 : 1,
        gridRow: gridRow,
        gridColumn: `${gridColumn} / span ${gridColumnSpan}`,
        position: 'relative',
        marginBottom: hasGap ? '20px' : '0px',
    }
}

function DraggableEvent({
    id,
    personSite,
    className,
    gridRow,
    gridColumn,
    gridColumnSpan,
    actualColumn,
    actualColumnSpan,
    isActive,
    moveResizeCell,
    onClick,
    props,
    timeFrame,
    gridContainer,
    mode,
    isNew,
    hasGap,
}: Props) {
    const previewRef = React.useRef<HTMLDivElement>(null);
    const [isEditing, setIsEditing] = React.useState<boolean>(!!isNew);
    const [localname, setLocalName] = React.useState<string | undefined>('');
    const [boundingRect, setBoundingRect] = React.useState<{
        y: number,
        height: number,
    }>();

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: ItemTypes.EVENT,
            item: () => ({
                id,
                props,
                gridRow,
                gridColumn,
                gridColumnSpan,
                actualColumn,
                actualColumnSpan,
                personSite,
                posY: boundingRect?.y || 0,
                height: boundingRect?.height || 0,
            }),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            })
        }),
        [props]
    );

    useLayoutEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: false });
        if (previewRef.current && gridContainer.current) {
            const boundingRect = previewRef.current.getBoundingClientRect();
            const containerBoundingRect = gridContainer.current.getBoundingClientRect();
            setBoundingRect({
                y: boundingRect.y - containerBoundingRect.y,
                height: boundingRect.height
            });
        }
    }, [gridRow]);

    props.paddingLeft = className !== undefined && className.indexOf('pl-0') >= 0 ? 0 : 1;
    props.paddingRight = className !== undefined && className.indexOf('pr-0') >= 0 ? 0 : 1;

    const onRightDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const rect = previewRef.current?.getBoundingClientRect();
        const startWidth = rect?.width || 0;
        const startX = e.pageX;

        const mouseMove = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            const newWidth = startWidth + (e.pageX - startX);
            previewRef.current?.style.setProperty('width', `${newWidth}px`);
        }

        window.addEventListener('mousemove', mouseMove);

        const mouseUp = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);

            const colDiff = (e.pageX - startX);
            const cellWidth = startWidth / gridColumnSpan;

            moveResizeCell(personSite, 0, Math.round(colDiff / cellWidth), 0, gridRow)
                .then(() => {
                    previewRef.current?.style.removeProperty('width');
                });
        }

        window.addEventListener('mouseup', mouseUp);
    }

    const onLeftDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const rect = previewRef.current?.getBoundingClientRect();
        const startWidth = rect?.width || 0;
        const startX = e.pageX;

        const mouseMove = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            const newWidth = startWidth + (startX - e.pageX);
            previewRef.current?.style.setProperty('width', `${newWidth}px`);
            previewRef.current?.style.setProperty('left', `${e.pageX - startX}px`);
        }

        window.addEventListener('mousemove', mouseMove);

        const mouseUp = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);

            const colDiff = (e.pageX - startX);
            const cellWidth = startWidth / gridColumnSpan;

            moveResizeCell(personSite, Math.round(colDiff / cellWidth), 0, 0, gridRow)
                .then(() => {
                    previewRef.current?.style.removeProperty('left');
                    previewRef.current?.style.removeProperty('width');
                });
        }

        window.addEventListener('mouseup', mouseUp);
    }

    const onDelete = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if(window.confirm('Arbeitseinteilung wirklich löschen?')) {
            store.dispatch(deletePersonSite(personSite));
        }
    };

    const loadOptions = async ( inputValue: string ) => {
        if(mode === viewModes.Site){
            const jsCompatibleDate = (date: string) => {
                return date.substring(0, 10) + 'T' + date.substring(11, 19) + 'Z';
            }
            const res = await fetcher(`${process.env.REACT_APP_API_URL}/Availability?Start=${jsCompatibleDate(getStartDateDB(personSite?.StartTime))}&End=${jsCompatibleDate(getEndDateDB(personSite?.EndTime, 0))}`);
            const data: db_Availability[] = await res.json();
            const options = data.map(item => ({value: item.id_Person, label: `${getShortName(item.Name)}: ${item.Days}d`})).filter(item =>
                inputValue.length === 0 || item.label.toLowerCase().includes(inputValue.toLowerCase()));
            return options;
        }
        else if(mode === viewModes.Person)
        {
            const res = await fetcher(`${process.env.REACT_APP_API_URL}/Site`);
            const data: db_Site[] = await res.json();
            const options = data.map(item => ({value: 'id_Site' in item && item.id_Site, label: `${item.Name}`})).filter(item =>
                inputValue.length === 0 || item.label.toLowerCase().includes(inputValue.toLowerCase()));
            return options;
        }
        else{
            console.error('Invalid mode in loadOptions');
            return [];
        }
    };

    const onChange = (newValue: any) => {
        if(newValue != null && personSite != null){
            const newPersonSite = { ...personSite };
            if(mode === viewModes.Site){
                newPersonSite.id_Person = newValue.value;
            }
            else if(mode === viewModes.Person){
                newPersonSite.id_Site = newValue.value;
            }
            else
            {
                console.error('Invalid value in onChange');
            }
            store.dispatch(updatePersonSite(newPersonSite, personSite));
        }
    };

    const handleInputChange = (inputValue: any, actionMeta: any) => {
        setLocalName(inputValue);
    };


    return (
        <div
            ref={previewRef}
            className={className + (isDragging ? ' cursor-grabbing' : '')}
            style={getStyles(gridRow, gridColumn, gridColumnSpan, isDragging, hasGap)}
            draggable={true}
            onClick={e => onClick(e, isActive)}
        >
            {actualColumn >= 2 && isActive ?
                <div style={{
                    position: 'absolute',
                    // left: '5px',
                    top: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                    cursor: 'e-resize',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    padding: '5px',
                    backgroundColor: 'white'
                }} draggable={true} onMouseDown={onLeftDragStart} onClick={ (e) => { e.stopPropagation(); } }>
                    <ChevronDoubleLeftIcon style={{
                        width: '1em',
                        height: '1em'
                    }} />
                </div>
                : null}
            <Event ref={drag} isActive={isActive} personSite={personSite} {...props} />
            {actualColumn + actualColumnSpan <= (timeFrame * 2) + 2 && isActive ?
                <div>
                    <div style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateX(50%) translateY(-50%)',
                        cursor: 'e-resize',
                        borderRadius: '50%',
                        border: '1px solid #ccc',
                        padding: '5px',
                        backgroundColor: 'white'
                    }} draggable={true} onMouseDown={onRightDragStart} onClick={ (e) => { e.stopPropagation(); } }>
                        <ChevronDoubleRightIcon style={{
                            width: '1em',
                            height: '1em'
                        }} />
                    </div>
                    {isEditing ? <div style={{
                        position: 'absolute',
                        right: '30px',
                        top: '20%',
                        transform: 'translateX(50%) translateY(-50%)',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: '1px solid #ccc',
                        padding: '5px',
                        backgroundColor: 'orange'
                    }} onClick={onDelete}>
                        
                        <div style={{
                            width: '1em',
                            height: '1em'
                        }} ><TrashIcon /></div>
                    </div>
                    : null}
                    <div onClick={ (e) => { e.stopPropagation(); setIsEditing(!isEditing)} } style={{
                        position: 'absolute',
                        right: '30px',
                        top: '80%',
                        transform: 'translateX(50%) translateY(-50%)',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: '1px solid #ccc',
                        padding: '5px',
                        backgroundColor: isEditing ? 'lightgreen' : 'lightblue'
                    }}>
                        <div style={{
                            width: '1em',
                            height: '1em'
                        }} >{isEditing ? 
                            <CheckIcon /> : 
                            <PencilIcon />}
                        </div>
                    </div>
                    {isEditing ? <div style={{
                        position: 'absolute',
                        left: '20px',
                        top: '50%',
                        right: '45px',
                        transform: 'translateX(0%) translateY(-50%)',
                    }}>
                        <AsyncSelect menuPortalTarget={document.body} menuPosition={'fixed'} cacheOptions defaultOptions styles={{ control: base => ({...base, maxHeight: 35}), menuPortal: base => ({ ...base, zIndex: 5, minWidth: '100px' }) }}
                            inputValue={ localname } placeholder={ props.name } loadOptions={ loadOptions } onChange={onChange} onInputChange={handleInputChange} isSearchable={true} />
                    </div>
                    : null}
                </div>
                : null}
        </div>
    )
}

export default DraggableEvent;