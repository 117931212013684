import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { db_Person, v_PersonSite, db_Site } from '../models';
import { ChevronLeftIcon, PlusIcon, PencilIcon, SaveAsIcon, SaveIcon, TrashIcon, CheckIcon } from '@heroicons/react/outline';
import { CustomButton, BackButton, EditButton, TrashButton, AddButton } from '../components/CustomButton';
import moment from 'moment';
import CustomInput from '../components/CustomInput';
import { fetcher } from '../request';
import CustomDatePicker from '../components/CustomDatePicker';
import { getStartDate, getEndDate, getStartDateDB, getEndDateDB } from '../helpers';

function SiteDetail() {
    let { siteId } = useParams();
    let [site, setSite] = useState<db_Site|null>(null);
    let [persons, setPersons] = useState<db_Person[]>([]);
    let [personsIndex, setPersonsIndex] = useState<{[key: number]: db_Person}>({});
    let [personSites, setPersonSites] = useState<v_PersonSite[]>([]);
    let [creatingPersonSite, setCreatingPersonSite] = useState(false);
    let [newStartDate, setNewStartDate] = useState('');
    let [newEndDate, setNewEndDate] = useState('');
    let [newPersonId, setNewPersonId] = useState(0);

    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + siteId);
        req.then(res => res.json()).then(data => setSite(data));

        const req2 = fetcher(process.env.REACT_APP_API_URL + '/Person');
        req2.then<db_Person[]>(res => res.json()).then(data => {
            setPersons(data);
            const personsIndex: {[key: number]: db_Person} = {};
            data.forEach(person => personsIndex[person.id_Person] = person);
            if (data.length > 0) {
                setNewPersonId(data[0].id_Person);
            }
            setPersonsIndex(personsIndex);
        }).then(() => {
            const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + siteId + '/Person');
            req.then(res => res.json()).then(data => setPersonSites(data));
        });
    }, [siteId, creatingPersonSite]);

    const createPersonSite = () => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + siteId + '/Person', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id_Person: newPersonId,
                id_Site: siteId,
                StartTime: getStartDateDB(newStartDate),
                EndTime: getEndDateDB(newEndDate, 1),
                Active: 1
            })
        });
        setCreatingPersonSite(false);
    }

    const deletePersonSite = (id: number) => {
        if(window.confirm('Arbeitseinteilung wirklich löschen?')) {
            const req = fetcher(process.env.REACT_APP_API_URL + '/PersonSite/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Active: 0
                })
            });
            setPersonSites(personSites.filter(e => e.id_PersonSite !== id));
        }
    };

    const finishSite = (e: any) => {
        e.preventDefault();
        let newEndDate = getEndDateDB(moment().startOf('day').subtract(1, 'days'), 1)
        const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + siteId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                //set end date to yesterday
                EndTime: newEndDate
            })
        }).then(res => {
            if (res.status === 200) {
                if(site !== null)
                {
                    let newSite = { ...site };
                    newSite.id_Site = newSite.id_Site as number;
                    newSite.EndTime = newEndDate;
                    setSite(newSite);
                }
            }
        });
    }

    const deleteSite = (e: any) => {
        e.preventDefault();
        if(window.confirm('Auftrag wirklich löschen?')) {
            if(site !== null)
            {
                const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + site?.id_Site, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Active: 0
                    })
                }).then(res => 
                {
                    if (res.status === 200) 
                    {
                        navigate('..')
                    }
                });
            }
        }
    }

    return (
        <div>
            <BackButton state = { state } navigate = { navigate } />
            <div className='flex flex-row item-center justify-between my-5'>
                <h1 className='text-2xl font-bold'>Auftrag</h1>
                <div className='flex justify-between space-x-2'>
                    {
                        //make visible if site has no end date or end date is in the future
                        site?.EndTime === null || moment(site?.EndTime).isAfter(moment()) ?
                            <CustomButton imageClassName='text-green-600' Icon={CheckIcon} onClick={ (e) => finishSite(e) }>Abschliessen</CustomButton>
                            : null
                    }
                    <EditButton onClick={(e) => { e.stopPropagation(); navigate('./edit') }} />
                    <TrashButton onClick={(e) => deleteSite(e) } />
                </div>
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{site?.Name}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Auftragsnummer</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{site?.NameShort}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getStartDate(site?.StartTime)}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Enddatum</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getEndDate(site?.EndTime)}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Budgetierte Stunden</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{site?.BudgetHours}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Voraussichtliches Ende</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getEndDate(site?.EndTimeBudget)}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Budget (CHF)</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{site?.Budget}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Malerarbeit aussen</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{site?.ExteriorWork ? '✅' : '🛑'}</dd>
                </div>
            </dl>
            <div className='flex item-center justify-between my-5'>
                <h2 className='text-xl font-bold'>Einsätze</h2>
                <AddButton onClick={() => setCreatingPersonSite(true)} />
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6 border-y border-gray-200">
                    <dt className="text-sm font-medium text-gray-500">Mitarbeiter</dt>
                    <dt className="text-sm font-medium text-gray-500">Start</dt>
                    <dt className="text-sm font-medium text-gray-500">Ende</dt>
                    <dt className="text-sm font-medium text-gray-500">Tage</dt>
                    <dt />
                </div>
                {personSites.map((personSite, index) => {
                    return (
                        <div key={personSite.id_PersonSite} className={(index % 2 == 0 ? 'bg-white' : 'bg-gray-50') + ' px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6'}>
                            <dd className="mt-1 text-sm text-gray-900">{personsIndex[personSite.id_Person]?.Name}</dd>
                            <CustomDatePicker className='flex' id='start-date' type='date' value={personSite.StartTime} url={`/PersonSite/${personSite.id_PersonSite}/StartTime`} />
                            <CustomDatePicker className='flex' id='end-date' type='date' value={personSite.EndTime} url={`/PersonSite/${personSite.id_PersonSite}/EndTime`} endDate={true} />
                            <dd className="mt-3 text-sm text-gray-900">{moment(personSite.EndTime).diff(personSite.StartTime, 'd')}</dd>
                            <dd className="mt-2 text-sm text-gray-900"><TrashButton onClick={() => deletePersonSite(personSite.id_PersonSite)} /></dd>
                        </div>
                    )
                })}
                {creatingPersonSite ? (
                        <div className={(personSites.length % 2 == 0 ? 'bg-white' : 'bg-gray-50') + ' px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6'}>
                            <dd className="mt-1 text-sm text-gray-900">
                                <select onChange={e => { setNewPersonId(parseInt(e.target.value)) }}>
                                    {persons.map(person =>
                                        <option key={person.id_Person} value={person.id_Person}>{person.Name}</option>
                                    )}
                                </select>
                            </dd>
                            <dd className="mt-1 text-sm text-gray-900">
                                <CustomDatePicker onChange={e => { setNewStartDate(getStartDateDB(e.target.value)) }} />
                            </dd>
                            <dd className="mt-1 text-sm text-gray-900">
                                <CustomDatePicker onChange={e => { setNewEndDate(getEndDateDB(e.target.value, 0)) }} />
                            </dd>
                            <dd />
                            <dd>
                                <CustomButton Icon={SaveIcon} onClick={createPersonSite}>Speichern</CustomButton>
                            </dd>
                        </div>
                    ) : null}
            </dl>
        </div>
    );
}

export default SiteDetail;